code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 750px) {
  .containerCode {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .cssCode {
    width: 100%;
    height: 275px;
  }

  .htmlCode {
    width: 100%;
    height: 275px;
    padding-bottom: 50px;
  }
}

@media (min-width: 750px) {
  .containerCode {
    display: flex;
    justify-content: space-around;
    padding: 20px;
  }

  .cssCode {
    width: 48%;
    height: 275px;
  }

  .htmlCode {
    width: 48%;
    height: 275px;
  }
}

.blob {
  margin: 7px;
  height: 10px;
  width: 10px;
  top: 0;
  right: 0;
  border-radius: 50%;
}

.htmlBlob {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px;
  background: #feb95a;
}

.cssBlob {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px;
  background: #377feb;
}

.heading {
  color: #fff;
  width: 100%;
  background-color: #161e29;
  display: flex;
  align-items: center;
  align-content: center;
  border-radius: 15px 15px 0 0;
}

.output {
  width: 100%;
}

.result {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.output iframe {
  border: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #f8f9fd;
  /* background: rgba(240, 240, 240, 0.8); */
  border-radius: 0 0 15px 15px;
}

.resultContainer {
  display: flex;
  flex-direction: column;
  margin: 20px;
  border-radius: 15px 15px 15px 15px;
  width: 800px;
}

.containerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.title {
  padding: 3px;
}

.CodeMirror {
  border-radius: 0px 0px 15px 15px !important;
}

body {
  /* background: #2a2e39; */
  background: #d6deeb;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.headerContainer {
  padding: 0 25px 75px 25px;
}

.header {
  height: 100px;
  width: 100%;
  background: #101922;
  border-radius: 0 0 25px 25px;
}

@media (max-width: 750px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .imgUser {
    border-radius: 50%;
    width: 100px;
    position: absolute;
    top: -50px;
  }

  .cardUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(240, 240, 240, 1);
    position: absolute;
    top: 55px;
    padding: 20px;
    border-radius: 15px;
    width: 200px;
    height: 90px;
    box-shadow: 4px 4px 15px 0px #000;
  }

  .menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: rgba(240, 240, 240, 1);
    top: 55px;
    padding: 20px;
    border-radius: 15px;
    margin: 20px 0px 0px 0px;
    height: 40px;
    box-shadow: 4px 4px 15px 0px #000;
    width: 75%;
  }
}

@media (min-width: 750px) {
  .imgUser {
    border-radius: 50%;
    width: 100px;
    position: absolute;
    margin-bottom: 130px;
  }

  .cardUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(240, 240, 240, 1);
    position: absolute;
    top: 55px;
    padding: 20px;
    margin: 0 60px;
    border-radius: 15px;
    width: 200px;
    height: 90px;
    box-shadow: 4px 4px 15px 0px #000;
  }

  .menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: rgba(240, 240, 240, 1);
    position: absolute;
    top: 55px;
    padding: 20px;
    margin: 0 360px;
    border-radius: 15px;
    width: calc(100vw - 470px);
    height: 40px;
    box-shadow: 4px 4px 15px 0px #000;
  }
}

.social {
  display: flex;
  height: 25px;
  width: 100%;
  justify-content: space-evenly;
}

.userData {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: center;
}

.profissionalName {
  font-size: 0.9rem;
}

.divider {
  height: 2px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  margin: 2px;
}

.socialIcon {
  cursor: pointer;
}
